<template>
<div style="display: flex;flex-direction: row;justify-content: center;">
    <div class="card card-icon mb-4 cardWidth">
        <div class="row g-0">
            <div class="col-auto card-icon-aside bg-info headMessage" style="background-color: #52b69a !important;">
                <span :style="mensajeLeido == 'S'? 'display: flex;':'display:none;'">
                    <i style="color: white !important" class="fas fa-envelope-open"></i>
                </span>
                <span :style="mensajeLeido != 'S'? 'display: flex;':'display:none;'">
                    <i style="color: white !important;" class="fas fa-envelope"></i>
                </span>
            </div>
            <div class="col">
                <div class="card-body m-0 p-2">
                    <div class="stTitulo p-1">
                        <h5 class="card-title" style="font-size: 1rem">{{infoMensaje.motivo}}</h5>
                        <h6 style="font-size:0.75rem">{{formateDate(infoMensaje.fecha)}}</h6>
                        
                    </div>
                     <div class="text-start p-1">
                        <h6 style="font-size:0.75rem" v-html="infoMensaje.resumen"></h6>
                     </div>
                    <div class="text-center">
                        <button type="button" @click="openLinkMsh(infoMensaje)" class="btn btn-primary lift lift-sm btn-alta" style="background-color: #002f87 !important;border: none;    font-size: 11px;letter-spacing:2px;padding-top:8px;padding-bottom:8px; margin: 0px 2px ">LEER</button>
                        
                        <button v-if="infoMensaje.adjuntos_JSON.length>0" type="button" @click="openMsg(infoMensaje.id,infoMensaje.adjuntos_JSON)" class="btn btn-primary lift lift-sm btn-alta" style="background-color: #002f87 !important;border: none;    font-size: 11px;letter-spacing:2px;padding-top:8px;padding-bottom:8px; margin: 0px 2px ">ADJUNTOS</button>

                        <!-- <button v-if="!(infoMensaje.link == null)" type="button" @click="irALink" class="btn btn-primary lift lift-sm btn-alta" style="background-color: rgb(82, 182, 154) !important;border: none;    font-size: 13px;letter-spacing:2px;padding-top:8px;padding-bottom:8px; margin: 0px 2px ">LINK</button> -->
                    </div>
         
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style>
    .btn-mensaje{
        text-align: left;
        background: inherit;
        border:none;
    }
    .noLeido {
        font-weight: 1000;
    }

    .stTitulo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .headMessage svg{
        height: 25px !important;
    }

    .cardWidth {
        width: 70%
    }

@media (max-width: 600px) {

    .cardWidth {
        width: 100%
    }

    .stTitulo {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
    }

    .headMessage {
        width: 100% !important;
        height: 35px !important;
        padding: 0px !important;
    }

    .headMessage svg{
        height: 20px !important;
    }

}

.btn-adjunto{
    background-color: rgb(82, 182, 154) !important;border: none;    font-size: 13px;letter-spacing:2px;padding-top:8px;padding-bottom:8px; margin: 0px 2px
}

#readmore {
    font-size: 0.75rem !important
}

/* .swal2-icon.swal2-info{
border: none;
} */

.swal2-content-mio{
    text-align: left;
}

.card-icon .card-icon-aside{
    padding:1.5rem
}

</style>

<script>
import UtilJS from '../../../../assets/js/util'
import Vue from 'vue'

export default {
    
    name: "Mensaje", 
    mixins: [UtilJS],   
    props: [
        'infoMensaje',
        ],
    data() {
        return {
            mensajeLeido: this.infoMensaje.leido
        }
    },
    computed: {
        mensajesNoLeidos() {
                return this.$store.state.mensajes.mensajesNoLeidos;
        },
    },
    methods: {
        openLinkMsh(msg){
            if (this.mensajeLeido == 'N') {
                this.mensajeLeido = 'S';
                this.$store.commit("mensajes/SET_MENSAJESNOLEIDOS", this.mensajesNoLeidos > 1? this.mensajesNoLeidos-1: 0);
            }
            // this.updateMensaje(id);
            var parser = new DOMParser();
	        var link = parser.parseFromString(msg.detalle, 'text/html').children[0].children[1].children[0].href;
             if(link.substr(0,3) == "www") {
                window.open("https://" + link);
            } else {
                window.open(link);
            }
        },
        openMsg(id,msg){
            Vue.swal({
                    title: "Adjuntos",
                    html: this.getBodyAdjuntos(msg),
                    confirmButtonText:'Cerrar',
                    content:'swal2-content-mio'
                })
        },
        getBodyAdjuntos(msg){
            let bodyElements='';
            msg.forEach(element => {
                bodyElements = bodyElements +  `
                    <div class="col-6 col-md-6 text-center mb-4">`+element.nombre+`</div>
                    <div class="col-6 col-md-6 text-center mb-4"><button type="button" class="btn btn-primary lift lift-sm btn-alta btn-adjunto"><a style="color: white !important;" href="`+element.url+`" target="_blank"><i class="fas fa-download"></i></a></button></div>
                `
            });
            let body = `
            <div class="row mt-4"><div class="col-6 col-md-6"> ARCHIVOS </div><div class="col-6 col-md-6"> DESCARGAS </div> </div>
            <div class="row mt-2"><hr> </div>
            <div class="row mt-0"> `+bodyElements+`</div>
            `
            return body;
        },
        updateMensaje(id){
            this.$store.dispatch("mensajes/updateMensaje",id)
        },
        cutMsg(msg){
            return msg.substring(0,25) + " ....";
        },
        irALink(){
            if(this.infoMensaje.link.substr(0,3) == "www") {
                window.open("https://" + this.infoMensaje.link);
            } else {
                window.open(this.infoMensaje.link);
            }
        }
    }

}
</script>
